<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img :src="appLogoImage"
             alt="logo" />
      <h2 class="brand-text text-primary mb-0 ml-1">
        {{ appName }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h1 class="mb-3">
          {{ errorCode }} - {{ error }}
        </h1>
        <b-button variant="primary"
                  class="mb-5 btn-sm-block"
                  :to="{path:'/'}">
          Accueil
        </b-button>

        <div>
          <b-img fluid
                 :src="require('@/assets/images/pages/error.png')"
                 alt="Error page" />
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
  import { BLink, BButton, BImg } from 'bootstrap-vue'
  import { $themeConfig } from '@themeConfig'

  export default {
    components: {
      BLink,
      BButton,
      BImg,
    },
    props: {
      error: {
        type: String,
        default: 'Erreur',
      },
      errorCode: {
        type: Number,
        default: 400,
      },
    },
    setup() {
      const { appName, appLogoImage } = $themeConfig.app
      return {
        appName,
        appLogoImage,
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
